import utilsService from "@/mixins/utils/axios/utils.service";
const addonEndpoint = `${process.env.VUE_APP_BASE_URL}/admin/addon`;

class AddonService {
    listAllAddons () {
        return utilsService.getRequest(`${addonEndpoint}/list-addons`);
    }
    insertAddon (addonObj) {
        return utilsService.postRequest(`${addonEndpoint}/insert`, addonObj);
    }
    updateAddon (addonObj) {
        return utilsService.putRequest(`${addonEndpoint}/update`, addonObj);
    }
    deleteAddon (addonId) {
        return utilsService.deleteRequest(`${addonEndpoint}/delete`, { id: addonId });
    }
}

export default new AddonService()
